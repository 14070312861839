import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import { createForm } from 'rc-form';
import { closeWindow } from '../../../apis/liff';
import {
  closeChatroom,
  getChatroom
} from '../../../actions/chatrooms';
import { getUrlParams } from '../../../helpers/location';
import { createChatroomSelectorById } from '../../../reducers/chatrooms';
import useActiveSession from '../../../hooks/useActiveSession';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Helmet from 'react-helmet';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Layout from '../../../components/Layout';
import DoctorPrescription from '../../../components/DoctorPrescription';
import DoctorDiseaseInput from '../../../components/DoctorDiseaseInput';

export default createForm()(({ location, form }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const [disease, setDisease] = React.useState('');
  const [prescription, setPrescription] = React.useState({});
  const params = getUrlParams(location.search);
  const { id } = params;
  const chatroom = useSelector(state => createChatroomSelectorById(state, id));
  const { validateFields, getFieldDecorator, getFieldError } = form;

  useActiveSession(() => {
    if (id) {
      dispatch(getChatroom(true, id));
    }
  }, [id]);

  const handleClick = () => {
    const bool = window.confirm('คุณยืนยันว่าข้อมูลทั้งหมดถูกต้อง?');
    if (bool) {
      validateFields(async (error, value) => {
        if (!error) {
          const res = {
            // final_diagnosis: 1, // ใช้ชื่อเดียวกับ AI
            disease_risk_text: disease,
            treatment_text: value.treatment_text,
            suggestion_text: value.suggestion_text,
            illness_text: value.illness_text,
            prescriptions: [],
            out_of_stock_prescriptions: []
          };

          if (Object.values(prescription).length) {
            Object.values(prescription).map(val => {
              let result = {
                medication_use: Object.assign({}, val.default_medication_use, val.medication_use),
                quantity: Number(val.quantity),
                // note: val.note
              }
              if (typeof(val.id) === "string") {
                result.product_name = val.id;
                (res.out_of_stock_prescriptions).push(result);
              } else {
                result.product = val.id;
                (res.prescriptions).push(result);
              }
            })
          }
          post(res);
        }
      });
    }
  }

  const post = async (payload) => {
    setLoading(true);
    try {
      await dispatch(closeChatroom(id, payload));
      alert('บันทึกข้อมูลเรียบร้อย');
      closeWindow();
    } catch (e) {
      alert('มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง');
    }
    setLoading(false);
  }

  const onPrescriptionChange = (val) => {
    setPrescription(val);
  }

  if (!chatroom) return false;

  const { customer } = chatroom;

  return (
    <Layout hideAppBar>
      <Helmet>
        <title>ลงสรุปข้อมูลเคส - MorOnline</title>
      </Helmet>
      <Box p={2}>
        <h2>ลงสรุปข้อมูลเคส</h2>
        <h5>วันที่ {new Date().toLocaleDateString()}</h5>
        <p>ชื่อ {customer.first_name} {customer.last_name}</p>
        <h4>ระบุโรคที่ตรวจพบ</h4>
        <DoctorDiseaseInput value={disease} onChange={setDisease}/>
        <h4>Medical Diagnosis (อาการที่พบ)</h4>
        {
          getFieldDecorator('illness_text', {
            rules: [{ required: true }],
          })(
            <TextField
              fullWidth
              multiline
              placeholder="ใส่รายละเอียด"
              rowsMax={20}
              error={getFieldError('illness_text')}
            />
          )
        }
        <h4>Medical Advice (คำแนะนำ)</h4>
        {
          getFieldDecorator('suggestion_text', {
            rules: [{ required: true }],
          })(
            <TextField
              fullWidth
              multiline
              placeholder="ใส่รายละเอียด"
              rowsMax={20}
              error={getFieldError('suggestion_text')}
            />
          )
        }
        <h4>Prescription</h4>
        <DoctorPrescription onChange={onPrescriptionChange} chatroom={id}/>
        <Box mt={4}>
          <Button
            fullWidth
            type="button"
            variant="contained"
            color="primary"
            disabled={loading}
            onClick={handleClick}
          >
            ยืนยัน
          </Button>
        </Box>
      </Box>
    </Layout>
  );
});
