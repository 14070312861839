import React from 'react';
import { getPrescriptionResources } from '../../apis';
import { createForm } from 'rc-form';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Input from '@material-ui/core/Input';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import Autocomplete from '@material-ui/lab/Autocomplete';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import SearchableMenuDialog from '../SearchableMenuDialog';

const ItemDialog = createForm()(({ form, open, product, onSubmit, onClose, onPrescriptionRemove }) => {
  const [resources, setResources] = React.useState();
  const { validateFields, getFieldDecorator, getFieldError, setFieldsValue } = form;

  React.useEffect(() => {
    getPrescriptionResources().then(res => {
      setResources(res);
    });
  }, []);

  React.useEffect(() => {
    if (product) {
      setFieldsValue(Object.assign({}, product.default_medication_use, product.medication_use));
    }
  }, [product]);

  const submit = () => {
    validateFields((error, value) => {
      if (!error) {
        onSubmit(product, Object.assign({}, product.default_medication_use, value));
      }
    });
  }

  const close = () => {
    setFieldsValue({});
    onClose();
    onPrescriptionRemove(product);
  }

  if (!product || !resources) return false;

  // const { default_medication_use = { unit, taken_frequency, taken_condition, dose, medication_use, extra_text } } = product;

  return (
    <Dialog
      disableBackdropClick
      open={open}
      onClose={close}
    >
      <DialogContent>
        <Box display="flex" alignItems="center" flexDirection="column">
          <h3>กรุณากรอกข้อมูล</h3>
          {
            getFieldDecorator('quantity', {
              rules: [{ required: true }],
            })(
              <TextField
                placeholder="จำนวน"
                type="number"
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {product.unit?.name}
                    </InputAdornment>
                  )
                }}
                error={getFieldError('quantity')}
              />
            )
          }
          <Box>
            {
              getFieldDecorator('dose', {
                // rules: [{ required: true }],
              })(
                <TextField
                  placeholder="รับประทานครั้งละ"
                  error={getFieldError('dose')}
                />
              )
            }
            {
              getFieldDecorator('unit', {
                // rules: [{ required: true }],
                initialValue: resources.units[0].id,
                getValueFromEvent: (e) => Number(e.target.value)
              })(
                <TextField
                  select
                  SelectProps={{
                    native: true,
                  }}
                  error={getFieldError('unit')}
                >
                  {resources.units.map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.name}
                    </option>
                  ))}
                </TextField>
              )
            }
          </Box>

          {
            getFieldDecorator('taken_frequency', {
              // rules: [{ required: true }],
              getValueFromEvent: (e, val) => val
            })(
              <Autocomplete
                multiple
                fullWidth
                filterSelectedOptions
                options={resources.taken_frequencies.map(val => val.id)}
                getOptionLabel={(option) => (resources.taken_frequencies.find(val => val.id === option) || {}).name}
                error={getFieldError('taken_frequency')}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    placeholder="รับประทานทุก"
                  />
                )}
              />
            )
          }

          {
            getFieldDecorator('taken_condition', {
              // rules: [{ required: true }],
              getValueFromEvent: (e, val) => val
            })(
              <Autocomplete
                multiple
                fullWidth
                filterSelectedOptions
                options={resources.taken_conditions.map(val => val.id)}
                getOptionLabel={(option) => (resources.taken_conditions.find(val => val.id === option) || {}).name}
                error={getFieldError('taken_condition')}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    placeholder="ก่อน/หลังอาหาร"
                  />
                )}
              />
            )
          }

          {
            getFieldDecorator('medication_use', {
              // rules: [{ required: true }],
            })(
              <TextField
                placeholder="วิธีการรับประทาน"
                fullWidth
                multiline
                rows={4}
                error={getFieldError('medication_use')}
              />
            )
          }

          {
            getFieldDecorator('extra_text', {
            })(
              <TextField
                placeholder="ข้อแนะนำเพิ่มเติม"
                fullWidth
                multiline
                rows={4}
                error={getFieldError('extra_text')}
              />
            )
          }

        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={submit} color="primary">
          ยืนยัน
        </Button>
        <Button onClick={close} color="primary">
          ยกเลิก
        </Button>
      </DialogActions>
    </Dialog>
  );
});


export default ({ chatroom, onChange = () => { }, onRemove = () => { } }) => {
  const [dialog, setDialog] = React.useState();
  const [menuDialog, setMenuDialog] = React.useState();
  const [prescription, setPrescription] = React.useState({});

  const onSubmit = (product, value) => {
    onPrescriptionChange(product, value);
    setDialog();
  }

  const onSelect = (product) => {
    window.logEvent('select_content', { content_type: 'prescription', item_id: product.id + '', item_name: product.name });
    setPrescription({
      ...prescription,
      [product.id]: product,
    });
    setDialog(product);
  }

  const onPrescriptionRemove = (product) => {
    const res = { ...prescription };
    delete res[product.id];
    setPrescription(res);
  }

  const onPrescriptionChange = (product, val) => {
    const res = {
      ...prescription,
      [product.id]: {
        ...product,
        quantity: val.quantity,
        medication_use: val
      },
    };
    setPrescription(res);
    onChange(res);
  }

  const onSelectAddDrug = (product) => {
    setPrescription({
      ...prescription,
      [product.id]: {},
    });
    setDialog(product);
  }

  return (
    <>
      <SearchableMenuDialog
      chatroom={chatroom}
        open={menuDialog}
        onClose={() => setMenuDialog(false)}
        onSelect={onSelect}
        onSelectAddDrug={onSelectAddDrug}
      />
      <ItemDialog open={!!dialog} product={dialog} onSubmit={onSubmit} onClose={() => setDialog()} onPrescriptionRemove={onPrescriptionRemove} />
      <List dense>
        {
          Object.values(prescription).map((product) => {
            return (
              <ListItem
                key={product.id}
              >
                <ListItemText
                  primary={product.name}
                  secondary={product.quantity ? <small>จำนวน {product.quantity} {product.unit?.name}</small> :  <small>กรุณาใส่รายละเอียด</small>}
                />
                <ListItemSecondaryAction>
                  <IconButton size="small" onClick={() => setDialog(product)}>
                    <EditIcon fontSize="small" />
                  </IconButton>
                  <IconButton size="small" edge="end" onClick={() => onPrescriptionRemove(product)} style={{ marginLeft: 2 }}>
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            )
          })
        }
      </List>
      <Button
        fullWidth
        type="button"
        variant="outlined"
        startIcon={<AddCircleOutlineIcon />}
        onClick={() => setMenuDialog(true)}
      >
        เพิ่มยา
      </Button>
    </>

  )
}