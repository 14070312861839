import React from 'react';
import { getMenusForDoctorWithLimit } from '../../apis';
import { debounce } from '../../helpers/utility';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import AutoComplete from '../AutoComplete';
import empty from '../../../static/img/empty-notification.png';

export default ({ open, onClose, onSelect, onSelectAddDrug, chatroom }) => {
  const [query, setQuery] = React.useState();
  const [products, setProducts] = React.useState([]);

  React.useEffect(() => {
    fetch(query);
  }, [query])

  const fetch = async (query) => {
    if (query && query.length > 1) {
      window.logEvent('search', { search_term: query });
    }

    const res = await getMenusForDoctorWithLimit(20)(query, chatroom);

    setProducts(res.results);
  }

  const select = (product) => {
    onSelect(product);
    onClose();
  }

  const addDrugSelect = (productName) => {
    const product = {id : productName, name: productName}
    onSelectAddDrug(product);
    onClose();
  };

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
      scroll="paper"
    >
      <DialogTitle>เลือกยา</DialogTitle>
      <DialogContent>
        <Box height="100%" overflow="hidden" display="flex" flexDirection="column">
          <Box my={1}>
            <AutoComplete
              placeholder="ใส่คำค้นหา"
              onSelect={debounce(setQuery, 500)}
            />
          </Box>
          <h4>รายการยา</h4>
          <Box my={1} height="100%" overflow="scroll">
            <List dense>
              {
                products.length === 0 && query
                ?
                  <ListItem
                    button
                    onClick={() => addDrugSelect(query)}
                  >
                    <ListItemAvatar>
                      <Avatar src={empty} alt="empty"/>
                    </ListItemAvatar>
                    <ListItemText
                      primary={<Typography>ไม่พบ {query} คุณต้องการเพิ่มยานี้หรือไม่?</Typography>}
                    />
                  </ListItem>
                : products.map((product) => {
                  return (
                    <ListItem
                      key={product.id}
                      button
                      onClick={() => select(product)}
                    >
                      {
                        product.image && (
                          <ListItemAvatar>
                            <Avatar
                              alt={product.name}
                              src={product.image.medium}
                            />
                          </ListItemAvatar>
                        )
                      }
                      <ListItemText
                        primary={product.name}
                        secondary={product.dose && `Dose: ${product.dose}`}
                      />
                    </ListItem>
                  )
                })
              }
            </List>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          ปิด
        </Button>
      </DialogActions>
    </Dialog>
  )
}