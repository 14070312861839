import React from 'react';
import { getDiseaseList } from '../../apis';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import Link from '@material-ui/core/Link';

function ItemDialog({ list, onSubmit, onClose }) {
  const [currentList, setCurrentList] = React.useState(list.length ? list : ['']);
  const [suggestList, setSuggestList] = React.useState([]);

  const submit = () => {
    onSubmit(currentList.filter(val => !!val).join(','));
  }

  const onTextChanged = (index) => (e) => {
    const newList = [...currentList];
    newList[index] = e.target.value;
    setCurrentList(newList);

    fetchSuggestion(index, newList[index]);
  }

  const onSelect = (index) => (e, newValue) => {
    window.logEvent('select_content', { content_type: 'disease', item_id: newValue + '', item_name: newValue + '' });

    const newList = [...currentList];
    newList[index] = newValue;
    setCurrentList(newList);
  }

  const fetchSuggestion = async (index, val) => {
    const res = await getDiseaseList(val);
    const newList = [...suggestList];
    newList[index] = res.results;
    setSuggestList(newList);
  }

  return (
    <Dialog
      fullScreen
      open
      onClose={onClose}
    >
      <DialogContent>
        {
          currentList.map((val, index) => {
            // <div><TextField fullWidth value={val} placeholder="ใส่ชื่อโรค" onChange={onTextChanged(index)} /></div>);
            return (
              <Autocomplete
                key={index}
                freeSolo
                fullWidth
                value={val}
                onChange={onSelect(index)}
                options={(suggestList[index] || []).map((option) => option.name)}
                renderInput={(params) => (
                  <TextField {...params} placeholder="ใส่ชื่อโรค" margin="normal" variant="outlined" onChange={onTextChanged(index)} />
                )}
              />
            )
          })
        }
        <Button
          startIcon={<AddIcon />}
          onClick={() => setCurrentList([...currentList, ''])}
        >เพิ่มโรค</Button>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          ยกเลิก
        </Button>
        <Button onClick={submit} color="primary">
          ตกลง
        </Button>
      </DialogActions>
    </Dialog>
  );
}


export default ({ value, onChange }) => {
  const [dialog, setDialog] = React.useState();

  const onSubmit = (value) => {
    onChange(value);
    setDialog();
  }

  return (
    <>
      {
        dialog && <ItemDialog list={value.split(',')} onSubmit={onSubmit} onClose={() => setDialog()} />
      }
      <div>
        <Link
          color="secondary"
          onClick={e => {
            e.preventDefault();
            setDialog(true);
          }}>{value || 'คลิกเพื่อใส่ข้อมูล'}</Link>
      </div>
    </>

  )
}